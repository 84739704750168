import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    EVENTOS: 'Eventos'
}

export default [
    {
        name: ROUTER_NAMES.EVENTOS,
        path: '/nossos-eventos',
        meta: {
            menu: MENUS.SISTEMA,
            searchAlias: 'Eventos',
            searchDescription: 'Veja todas os Eventos cadastrados',
            searchTerms: ['eventos'],
            hasRole: 'role/eventos'
        },
        component: () => import('@/views/Eventos')
    },
]