var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"px-3"},[_c('v-card-text',{staticClass:"subtitle-1 font-weight-light"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('search-box',{attrs:{"label":"* Evento","popup-label":"Selecione um Evento","id":_vm.value.idCompEvento,"descricao":_vm.value.descCompEvento,"value":_vm.evento,"loader-fn":_vm.loaderEventos,"item-key":"id","item-text":"descricao","rules":[
                      this.$validators.notNullOrUndefined
                  ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idCompEvento", $event)},"update:descricao":function($event){return _vm.$set(_vm.value, "descCompEvento", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum Evento encontrado ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":"* Ano Competência","rules":[
                      this.$validators.number.required,
                      function (v) { return this$1.$validators.number.greaterThanOrEquals(v, this$1.anoPermitidoInicio()); },
                      function (v) { return this$1.$validators.number.lessThanOrEquals(v, this$1.anoPermitidoFim()); }
                  ],"type":"number"},model:{value:(_vm.value.anoCompetencia),callback:function ($$v) {_vm.$set(_vm.value, "anoCompetencia", $$v)},expression:"value.anoCompetencia"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('search-box',{attrs:{"label":"* Etapa","popup-label":"Selecione uma Etapa","id":_vm.value.idCompEtapa,"descricao":_vm.value.descCompEtapa,"value":_vm.etapa,"loader-fn":_vm.loaderEtapas,"item-key":"id","item-text":"descricao","rules":[
                      this.$validators.notNullOrUndefined
                  ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idCompEtapa", $event)},"update:descricao":function($event){return _vm.$set(_vm.value, "descCompEtapa", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhuma Etapa encontrada ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"* Data Inicio Insc.","dense":"","type":"date","rules":[
                    this.$validators.string.required,
                    function (v) { return this$1.$validators.string.lessThanOrEquals(v, 10); }
                ]},model:{value:(_vm.value.dtInicioInscricao),callback:function ($$v) {_vm.$set(_vm.value, "dtInicioInscricao", $$v)},expression:"value.dtInicioInscricao"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"* Data Fim Insc.","dense":"","type":"date","rules":[
                      this.$validators.string.required,
                      function (v) { return this$1.$validators.string.lessThanOrEquals(v, 10); }
                  ]},model:{value:(_vm.value.dtFimInscricao),callback:function ($$v) {_vm.$set(_vm.value, "dtFimInscricao", $$v)},expression:"value.dtFimInscricao"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('lookup-dominio',{attrs:{"label":"* Status","type":_vm.lookups.status,"hidden-clear":false},model:{value:(_vm.value.status),callback:function ($$v) {_vm.$set(_vm.value, "status", $$v)},expression:"value.status"}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"px-3"},[_c('v-card-title',[_c('v-icon',{attrs:{"left":"","color":"red"}},[_vm._v("mdi-cog-outline")]),_c('span',{staticClass:"title"},[_vm._v("Configurações")])],1),_c('v-card-text',{staticClass:"subtitle-1 font-weight-light"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('lookup-dominio',{attrs:{"hint":"* Tipo Comp","radio":true,"type":_vm.lookups.flgTipoComp,"hidden-clear":false},model:{value:(_vm.value.flgTipoComp),callback:function ($$v) {_vm.$set(_vm.value, "flgTipoComp", $$v)},expression:"value.flgTipoComp"}})],1),_c('v-col',{staticClass:"d-flex align-end justify-end",attrs:{"cols":"12","sm":"3"}},[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.setModeloPadrao()}}},[_vm._v(" Modelo Padrão ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"disabled":!this.flgValorUnico,"label":"* Valor Único","prefix":"R$","rules":[
                  function (v) { return this$1.$validators.number.greaterThanOrEquals(v, 0); }
              ],"type":"number"},model:{value:(_vm.value.valorUnico),callback:function ($$v) {_vm.$set(_vm.value, "valorUnico", $$v)},expression:"value.valorUnico"}})],1),(_vm.value.flgTipoComp === 'UN')?_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-switch',{attrs:{"label":"Replicar Valor Único?"},model:{value:(_vm.flgValorUnico),callback:function ($$v) {_vm.flgValorUnico=$$v},expression:"flgValorUnico"}})],1):_vm._e(),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.flgTipoComp ==='CA'),expression:"value.flgTipoComp ==='CA'"}],attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"* Valor Promocional","prefix":"R$","rules":[
                  function (v) { return this$1.$validators.number.greaterThanOrEquals(v, 0); }
              ],"type":"number"},model:{value:(_vm.value.valorPromocional),callback:function ($$v) {_vm.$set(_vm.value, "valorPromocional", $$v)},expression:"value.valorPromocional"}})],1)],1),(this.value.flgTipoComp !== 'SO')?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('CardPersonalizado',{attrs:{"title":_vm.tituloOpcoes.un,"icon":"playlist-plus","action":_vm.addOpcao}},_vm._l((_vm.value.unicas),function(unica,index){return _c('v-row',{key:index},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"* Descrição","dense":""},model:{value:(unica.descricao),callback:function ($$v) {_vm.$set(unica, "descricao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"unica.descricao"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{attrs:{"label":"* Valor","dense":"","disabled":_vm.flgValorUnico,"type":"number"},model:{value:(unica.valor),callback:function ($$v) {_vm.$set(unica, "valor", $$v)},expression:"unica.valor"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-autocomplete',{attrs:{"multiple":true,"label":"Formas de pagamento","items":_vm.formasPagamento,"rules":[
                      _vm.validators.array.required ],"item-text":"valor","dense":true,"item-value":"key"},model:{value:(unica.idFormaPagamento),callback:function ($$v) {_vm.$set(unica, "idFormaPagamento", $$v)},expression:"unica.idFormaPagamento"}})],1),_c('v-col',{staticClass:"d-flex align-end justify-end",attrs:{"cols":"12","sm":"1"}},[_c('v-btn',{attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.removeOpcao(index)}}},[_c('v-icon',[_vm._v("mdi-playlist-remove")])],1)],1)],1)}),1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }