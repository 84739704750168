<template>
  <v-row>
    <v-col cols="12" sm="12">
      <search-box
          :emit-all-properties="true"
          label="* Evento"
          :disabledCampos="disabledCampos"
          popup-label="Selecione uma Evento Ativa"
          :id.sync="value.idCompCompeticao"
          :descricao.sync="value.descInscricaoCompeticao"
          :idCompEvento.sync="value.idCompEvento"
          :flgTipoComp.sync="value.flgTipoComp"
          :flgValorUnico.sync="value.flgValorUnico"
          :valorInscricao.sync="value.valorInscricao"
          :unicas.sync="value.unicas"
          :value="competicao"
          :loader-fn="loaderCompeticoesAtivas"
          item-key="id"
          item-text="descricao"
          :rules="[
                this.$validators.string.required,
                this.$validators.notNullOrUndefined
            ]">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum Evento ativo encontrado
          </p>
        </template>
      </search-box>
    </v-col>
    <v-col cols="12" sm="12">
      <v-row v-if="value.flgTipoComp === 'UN'">
        <v-col cols="12" sm="12">
          <strong>Opções disponíveis</strong> <v-icon @click="limpaProva()" >mdi-reload</v-icon>
          <v-radio-group v-model="opcaoInscricao" :disabled="disabledCampos" :dense="true" column @change="selecionaOpcao">
            <v-radio
              v-for="option in this.value.unicas"
              :key="option.id"
              color="red"
              :label="option.descricao"
              :value="option.id"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="12">
      <v-text-field dense
          label="Valor da Inscrição"
          prefix="R$"
          outlined
          disabled
          v-model="value.valorInscricao" 
          type="number"/>
    </v-col>
  </v-row>
</template>

<script>
import SearchBox from "../../../components/shared/common/SearchBox";
import LookupDominio from "../../../components/shared/common/LookupDominio";
import {ItemDeDominio} from "../../../api/itemsDominio";
import {findAllCompeticoesAtivasPublica} from "../../../api/competicoes";
import {ApiError} from "../../../api/core";

export default {
  name: "InscrevaSeForm",
  components: {LookupDominio, SearchBox},
  props: {
    value: {
      type: Object
    },
    idCompEvento: {
      type: Number
    },
    disabledCampos: {
      type: Boolean
    }
  },
  data () {
    return {
      lookups: {
        status: ItemDeDominio.STATUS_PADRAO
      },
      opcaoInscricao: null,
    }
  },
  watch: {
    'value.idCompCompeticao': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue){
          this.$emit('sendIdCompEventoToInscrevase',this.value.idCompEvento);
        }
      }
    },
  },  
  methods: {
    loaderCompeticoesAtivas(page, search) {
      //console.log('chamou => methods.loaderCompeticoesAtivas')
      return findAllCompeticoesAtivasPublica(page, this.idCompEvento, {
        descricao: search
      })
    },
    limpaProva() {
      this.opcaoInscricao = null;
      this.value.valorInscricao = 0;
      this.value.unicasId = null;
    },
    selecionaOpcao(opcaoId) {
      const selected = this.value.unicas.find(opcoes => opcoes.id === opcaoId);
      if (selected) {
        this.value.unicasId = selected.id;
        this.value.valorInscricao = selected.valor;
      }
    }
  },
  computed: {
    competicao() {
      if(this.value.idCompCompeticao == null) {
        this.value.descInscricaoCompeticao = null;
        this.value.idCompEvento = null;
        this.value.flgTipoComp = null;
        this.value.flgValorUnico = null;
        this.value.valorInscricao = null;
        this.value.unicas = {};
        this.value.unicasId = null;
        return null
      };
      return {
        id: this.value.idCompCompeticao,
        descricao: this.value.descInscricaoCompeticao,
        idCompEvento: this.value.idCompEvento,
        flgTipoComp: this.value.flgTipoComp,
        flgValorUnico: this.value.flgValorUnico,
        valorInscricao: this.value.valorInscricao,
        unicas: this.value.unicas,
        unicasId: this.value.unicasId
      }
    },
  },
  mounted() {
  }
}
</script>

<style scoped>
.observacao-provas {
  
}
</style>