import matriculas from "./modulos/matriculas";
import Vue from 'vue'
import VueRouter from 'vue-router'
import locais from "./modulos/locais";
import clientes from "./modulos/clientes";
import relatorios from './modulos/relatorios';
import store from '../store';
import alterarSenha from "./modulos/alterarSenha";
import parametros from "./modulos/parametros";
import funcionarios from "./modulos/funcionarios";
import mensagens from "./modulos/mensagens";
import areaCliente from "./modulos/areaCliente";
import painel from "./modulos/painel";
import eventos from "./modulos/eventos";
import etapas from "./modulos/etapas";
import competicoes from "./modulos/competicoes";
import inscricoes from "./modulos/inscricoes";
import faturas from "./modulos/faturas";

Vue.use(VueRouter)

const routes = [
    ...matriculas,
    ...locais,
    ...clientes,
    ...relatorios,
    ...alterarSenha,
    ...parametros,
    ...funcionarios,
    ...mensagens,
    ...areaCliente,
    ...painel,
    ...eventos,
    ...etapas,
    ...competicoes,
    ...inscricoes,
    ...faturas,
    {
        name: 'LoginCliente',
        path: '/login/cliente',
        props: {
            type: 'cliente'
        },
        meta: {
            loginPage: true
        },
        component: () => import('@/views/Login')
    },
    {
        name: 'Unauthorized',
        path: '/unauthorized',
        meta: {
            title: 'Sem Autorização',
            unauthorized: true,
        },
        component: () => import('@/views/Unauthorized')
    },
    {
        name: 'LoginFuncionario',
        path: '/login/funcionario',
        props: {
            type: 'funcionario'
        },
        meta: {
            loginPage: true
        },
        component: () => import('@/views/Login')
    },
    {
        name: 'Cadastre-se',
        path: '/cadastre-se',
        props: {
            ePrematricula: false,
            titulo: "Cadastre-se"
        },         
        meta: {
            loginPage: true
        },
        component: () => import('@/views/Cadastrese')
    },
    {
        name: 'PreMatricula',
        path: '/pre-matricula',
        props: {
            ePrematricula: true,
            titulo: "Pré-Matrícula"
        },        
        meta: {
            loginPage: true
        },
        component: () => import('@/views/Inscrevase')
    },
    {
        name: 'NossosEventos',
        path: '/eventos/:id?/:nomeEvento?',
        meta: {
            loginPage: false
        },
        component: () => import('@/views/Inscrevase')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    await store.restored;
    const {meta} = to;

    function isGoingToLoginPage() {
        return meta.hasOwnProperty('loginPage') && meta.loginPage;
    }

    function isGoingToUnauthorizedPage() {
        return meta.hasOwnProperty('unauthorized') && meta.unauthorized;
    }

    async function handleIsAuthenticated() {
        const defaultHomePage = await store.dispatch('auth/getDefaultHomePage');
        if (isGoingToLoginPage()) {
            next({
                name: defaultHomePage
            });
        } else {
            const authorizedLinks = [...store.getters['auth/authorizedLinks']];
            if (isGoingToUnauthorizedPage()) {
                next();
            } else {
                if (authorizedLinks.some(item => item.startsWith(retornaPrimeiroNivelUrl(to.path)))) {
                    next();
                } else {
                    if (to.path === '/') {
                        next({
                            name: defaultHomePage
                        })
                    } else {
                        next('/unauthorized')
                    }
                }
            }
        }
    }

    async function handleNotAuthenticated() {
        const authorizedLinks = [...store.getters['auth/authorizedLinks']];
        const defaultLoginPage = await store.dispatch('auth/getDefaultLoginPage');
        if (to.path === '/') {
            next({
                name: defaultLoginPage
            })
        } else if (authorizedLinks.some(item => item.startsWith(retornaPrimeiroNivelUrl(to.path)))) {
            next();
        } else if (isGoingToLoginPage()) {
            next();
        } else {
            next({
                name: defaultLoginPage
            })
        }
    }

    if (store.getters['auth/isAuthenticated']) {
        await handleIsAuthenticated();
    } else {
        await handleNotAuthenticated();
    }

    function retornaPrimeiroNivelUrl(path){
        const arrPath = path.split('/');
        const primeiroItem = arrPath[1];
        return "/"+primeiroItem;
    }

})

export default router
