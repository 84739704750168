<template>
  <base-view
      @click:add="criar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">

    <CreateEditCompeticaoDialog :item="item" v-model="editDialogOpened"
                                  @onSave="() => $refs.dataTable.refresh()"/>

    <div>
      <CompeticoesDataTable ref="dataTable"
                             @click:edit="editar"
                             @click:remove="remover"
                         :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import CompeticoesDataTable from "../../components/shared/datatables/CompeticoesDataTable";
import BaseView from "../BaseView";
import {remove} from "../../api/competicoes";
import CreateEditCompeticaoDialog from "../../components/shared/dialogs/CreateEditCompeticaoDialog";
import {findAll as findAllEvento} from '../../api/eventos';
import {findAll as findAllEtapa} from '../../api/etapas';
import {ItemDeDominio} from "../../api/itemsDominio";
import {mapActions} from "vuex";

export default {
  name: "index",
  components: {CreateEditCompeticaoDialog, BaseView, CompeticoesDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'Descricao',
          column: 'descricao',
          type: 'text'
        },
        {
          name: 'Ano Competição',
          column: 'anoCompetencia',
          type: 'number'
        },
        {
          name: 'Evento',
          column: 'idCompEvento',
          type: 'autocomplete',
          optionsFactory: (search) => findAllEvento(1,{
            descricao: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })
        },
        {
          name: 'Etapa',
          column: 'idCompEtapa',
          type: 'autocomplete',
          optionsFactory: (search) => findAllEtapa(1,{
            descricao: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })
        },
        {
          name: 'Status',
          column: 'status',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.STATUS_PADRAO
          })
        },        
      ]
    }
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {
        idCompEvento: null,
        idCompEtapa: null,
        anoCompetencia: "",
        dtInicioInscricao: "",
        dtFimInscricao: "",
        status: "A",
        flgTipoComp: "SO",
        flgValorUnico: "N",
        valorInscricao: null,
        valorPromocional: null,
        unicas: [{
          id: "",
          flgTipoComp: "UN",
          descricao: "",
          idFormaPagamento: [],
          valor: "",
        }],
        casadinhas: [{
          id: "",
          flgTipoComp: "CA",
          descricao: "",
          idFormaPagamento: [],
          valor: "",          
        }],		        
      };
      this.editDialogOpened = true;
    },
    editar(aula) {
      this.item = JSON.parse(JSON.stringify(aula));
      this.editDialogOpened = true;
    },
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`Competição removida com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        this.$toast.error(`Não foi possível remover a Competição.`, {
          timeout: 6000
        })
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>